import Link from "next/link";
import { Component404Styles } from "./Component404Styles";

const Component404 = () => {
  return (
    <Component404Styles>
      <div className="container-404">
        <div className="title">404</div>
        <div className="subtitle">Oops...Something went wrong.</div>
        <div className="description">
          Sorry! We searched high and low but couldn't find what you were looking for. Click below to head home
        </div>
        <Link href="/" scroll={false} legacyBehavior>
          <a className="home-btn">HOME</a>
        </Link>
      </div>
    </Component404Styles>
  );
};

export default Component404;
