import styled from "styled-components";

export const Component404Styles = styled.div`
  background: #f9f7f3;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: calc(100vh - 68px);
  padding: 0 20px;

  .title {
    color: #1f00b7;
    font-size: 230px;
    font-weight: 300;
    line-height: 120px;
    letter-spacing: -0.03em;
    margin: auto;
    margin-bottom: 55px;

    @media only screen and (max-width: 520px) {
      font-size: 160px;
      line-height: 160px;
      margin-bottom: 5px;
    }
  }

  .subtitle {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    margin: auto;
    margin-bottom: 19px;

    @media only screen and (max-width: 520px) {
      font-size: 26px;
    }
  }

  .description {
    font-family: Tiempo;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    margin: auto;
    margin-bottom: 37px;
    width: 467px;
    max-width: 100%;

    @media only screen and (max-width: 520px) {
      width: 100%;
      font-size: 16px;
    }
  }

  .home-btn {
    display: block;
    margin: auto;
    background: #1f00b7;
    color: #fff;
    width: 157px;
    height: 60px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    padding: 20px 0;
    border: 1.6px solid #1f00b7;
    outline: none !important;
    transition: 0.5s ease all;
    cursor: pointer;

    &:hover {
      font-weight: 500;
      background: transparent;
      color: #1f00b7;
    }
  }
`;
